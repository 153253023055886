.form-control-group-input {
  position: relative;
}

.form-control-type-toggle {
  position: absolute;
  top: .3rem;
  right: .5rem;
}

.form-control-type-toggle label {
  padding-left: .25rem;
  padding-right: .25rem;
  top: .25rem;
  margin: 0;
  text-align: center;
}

.form-control-type-toggle input {
  width: 0;
}

.form-control-type-toggle~.form-control {
  padding-right: 2.25rem;
}

.rdw-editor-wrapper {
  /* height: 100%; */
  margin-bottom: .25rem;
}

.rdw-editor-toolbar {
  border: 1px solid #cdcdcd !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0.25rem 0.25rem 0 0!important;
}

.rdw-editor-main {
  border: 1px solid #cdcdcd;
  padding: 1rem;
  /* background-color: #fafafa; */
  border-radius: 0 0 0.25rem 0.25rem!important;
}

.search-form .number-pin-container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
/* .search-form .number-pin-container swd-pin-field {
  display: flex;
  flex-wrap: wrap;
} */

.search-form .number-pin-container .sep {
  line-height: 2rem;
  padding: 0 .25rem;
  text-align: center;
}

.search-form .number-pin-container .form-control {
  margin-right: .25rem;
  width: 2.25rem;
  padding-left: 0;
  padding-right: 0;
}

.search-form .number-pin-container swd-pin-field .form-control:last-child {
  margin-right: 0;
}
/* 
.search-form .number-pin-container.length-7 .form-control:nth-of-type(3),
.search-form .number-pin-container.length-7 .form-control:nth-of-type(6) {
    position: relative;
    margin-right: 1.25rem;
}

.search-form .number-pin-container.length-8 .form-control:nth-of-type(3),
.search-form .number-pin-container.length-8 .form-control:nth-of-type(7) {
    position: relative;
    margin-right: 1.25rem;
} */

@media only screen and (min-width: 768px) {
  .search-form .number-pin-container {
    flex-wrap: unset;
  }

  .search-form .number-pin-container swd-pin-field {
    flex-grow: 1;
    display: flex;
  }

  .search-form .number-pin-container .form-control {
    flex-grow: 1;
  }
}



.search-form .phone-number-like-chkbx,
.search-form .check-is-like {
  margin-right: .5rem;
  margin-bottom: .5rem;
}

.search-form .check-is-like {
  flex-grow: 1;
}

.search-form .phone-number-like-chkbx .mark, 
.search-form .check-is-like input ~ .mark {
  padding: .5rem .75rem;
  border-radius: .25rem;
  position: relative;
  color: var(--bs-gray-600);
  border: 1px solid var(--bs-gray-600);
  cursor: pointer;
  background-color: #fff;
  transition: all .25s;
  font-size: 90%;
  display: block;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .search-form .phone-number-like-chkbx .mark {
    padding: .5rem 1rem;
  }
}


.search-form .phone-number-like-chkbx.like .mark,
.search-form .check-is-like input:checked ~ .mark {
  background-color: var(--bs-success);
  border-color: var(--bs-success);
  color: var(--bs-white);
}

.search-form .phone-number-like-chkbx.dislike .mark,
.search-form .check-is-like.dislike input:checked ~ .mark {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
  color: var(--bs-white);
}

.search-form .phone-number-like-chkbx .mark:hover,
.search-form .check-is-like input:not(:checked) ~ .mark:hover {
  background-color: var(--bs-gray-600);
  color: var(--bs-white);
}

.search-form .check-is-like input {
  width: 0;
  position: absolute;
}

.search-form .phone-number-like-chkbx {
  margin-right: .25rem;
}
