.full-page-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  opacity: 0;
  flex-direction: column;
  color: #fff;
  pointer-events: none;
  transition: opacity 0.5s;
}

.full-page-loading.open {
  width: 100%;
  opacity: 1;
  pointer-events: all;
}

.full-page-loading .loading {
  width: 150px;
  height: 150px;
  opacity: 0;
  /* transform: translateY(-150px); */
  animation: loading-out-animation 0.5s forwards;
}

.full-page-loading.open .loading {
  /* transform: translateY(150px); */
  animation: loading-in-animation 0.5s forwards;
}

@keyframes loading-in-animation {
  0% {
    opacity: 0;
    /* transform: translateY(150px); */
    transition: ease-in;
  }
  100% {
    opacity: 1;
    /* transform: translateY(0px); */
  }
}

@keyframes loading-out-animation {
  0% {
    opacity: 1;
    /* transform: translateY(0); */
    transition: ease-out;
  }
  100% {
    opacity: 0;
    /* transform: translateY(-150px); */
  }
}

.btn-loading-icon {
  -moz-animation: btn-loading-icon-anim 1s infinite linear;
  -webkit-animation: btn-loading-icon-anim 1s infinite linear;
  -animation: btn-loading-icon-anim 1s infinite linear;
}
@-moz-keyframes btn-loading-icon-anim {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes btn-loading-icon-anim {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes btn-loading-icon-anim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* bubble */
.loading-spinning-bubbles {
  position: relative;
  margin: auto;
  height: 90px;
}
.loading-spinning-bubbles .bubble-container {
  position: absolute;
  top: calc(50% - 10px/2);
  left: calc(50% - 10px/2);
  transform-origin: -150% 50%;
}
.loading-spinning-bubbles .bubble-container .bubble {
  background: var(--bs-tertiary);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: bubble 1s infinite;
  animation-delay: inherit;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+1) {
  transform: translateX(200%) rotate(-90deg);
  animation-delay: -1.5s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+2) {
  transform: translateX(200%) rotate(-45deg);
  animation-delay: -1.375s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+3) {
  transform: translateX(200%);
  animation-delay: -1.25s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+4) {
  transform: translateX(200%) rotate(45deg);
  animation-delay: -1.125s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+5) {
  transform: translateX(200%) rotate(90deg);
  animation-delay: -1s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+6) {
  transform: translateX(200%) rotate(135deg);
  animation-delay: -0.875s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+7) {
  transform: translateX(200%) rotate(180deg);
  animation-delay: -0.75s;
}
.loading-spinning-bubbles .bubble-container:nth-of-type(0n+8) {
  transform: translateX(200%) rotate(225deg);
  animation-delay: -0.625s;
}

@keyframes bubble {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* bubble */
