.phone-number-recommended-container {
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
}

.phone-number-recommended-container .phone-number-item {
    margin-left: 5px;
    margin-right: 5px;
}

.phone-number-item {
    border: 1px solid;
    border-radius: .25rem;
    padding: .5rem .75rem;
    background-color: #ffffff;
    position: relative;
    /* width: 33.3333%; */
    /* display: inline-block; */
}

.phone-number-item .number h5 {
    font-weight: bold;
}

.phone-number-item.recommended .number {
    display: inline-block;
    /* padding: 0 .5rem; */
    border-radius: .25rem;
    /* background-color: black; */
}

.phone-number-item.recommended .number h5 {
    color: #faf0a0 !important;
    /* background-image: repeating-linear-gradient(to right, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, #d3b15f 27%,
            #faf0a0 35%, #ffffc2 40%, #faf0a0 50%, #d3b15f 75%,
            #b17b32 80%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%);
     */
    background-image: repeating-linear-gradient(to top, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, 
    #d3b15f 27%, #e0cf43 50%, #d3b15f 75%, #b17b32 80%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%);

    background-size: 210%;
    background-position: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.phone-number-item .provider-image img {
    width: 55px;
}


.phone-number-recommended-container .arrow {
    position: absolute;
    left: -45px;
    top: 0;
    height: 100%;
    line-height: 100%;
    border: 0;
    background-color: transparent;
    color: var(--bs-tertiary);
    font-size: 2.5rem;
    z-index: 9;
    transition: all .25s;
    text-align: right;
}

.phone-number-recommended-container .arrow.next {
    text-align: left;
    right: -45px;
    left: unset;
}

.phone-number-recommended-container .arrow::after {
    content: "";
    width: 2px;
    height: 2.25rem;
    background-color: var(--bs-tertiary);
    position: absolute;
    right: .75rem;
    top: 50%;
    margin-top: -1rem;
}

.phone-number-recommended-container .arrow.next::after {
    content: "";
    right: unset;
    left: .75rem;
}