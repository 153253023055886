#root, .page-focus-center {
    min-height: 100vh;

}
.page-focus-center {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}