$primary: #ffd200;;

@import "/node_modules/bootstrap/scss/_functions.scss";
@import "/node_modules/bootstrap/scss/_mixins.scss";
@import "/node_modules/bootstrap/scss/_variables.scss";

$tertiary: #c62d32;
$app-line: #20ba4f;
$app-whatsapp: #2bb740;
$app-fb: #1b74e4;


$custom-theme-colors: (
  "tertiary": $tertiary,
  "app-line": $app-line,
  "app-whatsapp": $app-whatsapp,
  "app-fb": $app-fb,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text: map-merge($utilities-text, $utilities-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text");
$utilities-bg: map-merge($utilities-bg, $utilities-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg");

@import "/node_modules/bootstrap/scss/bootstrap.scss";