/* .TUEFO {
    height: auto!important;
    margin-bottom: .75rem;
    padding-top: .75rem!important;
} */

.rdt_TableHeadRow {
    background-color: var(--bs-tertiary)!important;
    color: #ffffff!important;
    font-size: .9rem;
}
