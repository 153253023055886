@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@300;400;500;700&display=swap');

body {
  font-family: 'Noto Sans Lao', 'Noto Sans Thai', sans-serif;
  background-color: #f8f3ed;
  /* background-image: url(./img/bg/bg.jpg); */
  /* background-repeat: repeat; */
  /* background-size: 50% 50%; */
  /* background-position: center center; */
}

.swal-sm {
  width: 90% !important;
  max-width: 540px !important;
}

.swal-md {
  width: 90% !important;
  max-width: 768px !important;
}

.swal-lg {
  width: 90% !important;
  max-width: 992px !important;
}

.swal-xl {
  width: 90% !important;
  max-width: 1200px !important;
}

/* CUSTOM */
.dropdown-item.active,
.dropdown-item:active {
  color: #333;
}

.nav-link,
a {
  color: rgba(var(--bs-tertiary-rgb), 1);
}

.nav-link.active,
.nav-link:focus,
.nav-link:hover,
a:focus,
a:hover {
  color: rgba(var(--bs-tertiary-rgb), .75);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--bs-tertiary);
}


.pagination {
  margin: 15px 0;
}

.pagination ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination ul li a {
  min-width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin: 0 .15rem;
  transform: all .25s;
  display: block;
}

.pagination ul li a:hover,
.pagination ul li.selected a {
  background-color: var(--bs-primary);
}

.pagination ul li.previous a,
.pagination ul li.next a {
  padding: 0 8px;
  text-decoration: none;
  color: var(--bs-dark);
}

.recommended-title .bg {
  background-color: var(--bs-primary);
  display: inline-block;
  /* clip-path: polygon(0 0, 85% -0%, 100% 100%, 0% 100%); */
  clip-path: polygon(0% 0%, 75% 0%, 70% 50%, 75% 100%, 0% 100%);
  padding: 0.25rem 125px 0.25rem 60px;
  /* padding-right: 75px; */
  /* padding-left: 60px; */
  background-image: url(./img/bg/gd1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset -8px -2px 14px #8080804d;
  position: relative;
  margin-left: -35px;
  box-shadow: 1px 1px 1px #2f384282;
}

.recommended-title img {
  width: 60px;
  z-index: 100;
  position: relative;
  margin-top: -14px;
}

.recommended-title .bg h1 {
  margin: 0;
  color: #2f3842;
  text-shadow: #fff6c5 1px 1px 0;
}